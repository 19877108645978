import React from "react";
import { RxDoubleArrowDown } from "react-icons/rx";
import { Slide, Fade, JackInTheBox } from "react-awesome-reveal";
import hpic from "../img/hpic.png";
import test1 from "../img/test1.png";

import icon1 from "../img/1.png"
import icon2 from "../img/2.png"
import icon3 from "../img/3.png"

import ser1 from "../img/12.png";
import ser2 from "../img/11.png";
import ser3 from "../img/13.png";
import ser4 from "../img/14.png";
import ser5 from "../img/15.png";


export default function Home(){

	const Section1 = [
		{
			id: 1,
			name: 'Hair Shampoo',
			desc: 'Our nerves are too tired, due to thinking too much and also due to the pressure of life, making us have headaches, so we need to find professional hair Shampoo people to help us relieve those headaches.',
			img: icon1
		},
		{
			id: 2,
			name: 'Facial Massage',
			desc: ' Our face also needs to be taken care of to circulate blood and firm the skin, to help you regain the youth of your face, through professional movements from the staff, you will feel that after the Facial Massage is finished.',
			img: icon2
		},
		{
			id: 3,
			name: 'Apply Mask',
			desc: 'Collagen Mask to penetrate into the pores on the face and gradually penetrate deep into the cells under the skin layer to nourish the skin from the inside and also to nourish the cells to help slow down the aging process.',
			img: icon3
		}
	];

	const Section2 = [
		{
			id: 1,
			name: 'Relax Massage',
			desc1: '$50 30 mins',
			desc2: '$60 45 mins',
			img: ser1
		},
		{
			id: 2,
			name: 'Body Scrub',
			desc1: '$30 20 mins',
			desc2: '',
			img: ser2
		},
		{
			id: 3,
			name: 'Hair Oil Treatment',
			desc1: '$15 10 mins',
			desc2: '',
			img: ser3
		},
		{
			id: 4,
			name: 'Hair Shampoo',
			desc1: '$40 30 mins',
			desc2: 'Mask + $10',
			img: ser4
		},
		{
			id: 5,
			name: 'Special Combo',
			desc1: 'Relax Massage + Hair Shampoo+ Facial + Mask+ Hot stone or Heat bag',
			desc2: '$70 / 1 hour',
			img: ser5
		}
	];

	const Section3 = [
		{
			id: 1,
			name: 'Expertise and Care',
			desc: 'Our skilled therapists provide exceptional care, using techniques designed to alleviate stress and promote overall well-being.'
		},
		{
			id: 2,
			name: 'Luxurious Relaxation',
			desc: 'Indulge in calming, luxurious treatments that soothe both body and mind, helping you unwind from the daily pressures of life.'
		},
		{
			id: 3,
			name: 'Personalized Comfort',
			desc: 'Our tailored services are crafted to meet your individual needs in a serene atmosphere, ensuring you feel completely pampered and relaxed.'
		}
	];

	function scrollSec(){
        const home1 = document.getElementById('Services');
        home1?.scrollIntoView({
            behavior:"smooth"
        });
    };


    return(
        <main>
            <section className="w-screen bg-bg1 bg-no-repeat bg-center bg-cover h-screen relative">
                <div className="w-screen bg-black bg-opacity-20 flex h-screen flex-col text-center items-center justify-center px-3 py-5 space-y-5 lg:py-8 md:space-y-6">
                    <div className="space-y-2 !font-Playfair">
                        <h1 className="text-6xl italic capitalize ml-4 text-main-color md:text-8xl tracking-widest">
                            Relax Day Spa
                        </h1>
                    </div>
                    <div className="p-2">
                        <div className="">
                            <ul className=" text-white space-y-2 capitalize">
                                <li>
                                    <h2 className="text-xl md:text-2xl tracking-wide">Relaxation paves the path to tranquility</h2>
                                </li>
                                <li>
                                    <h2 className="text-xl md:text-2xl tracking-wide italic">When the body unwinds</h2>
                                </li>
                                <li>
                                    <h2 className="text-xl md:text-2xl tracking-wide">the soul discovers serenity.</h2>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <button className="hover:bg-white/0 hover:border-[1px] rounded-lg hover:border-red-600 transition duration-200 delay-100 md:text-xl text-white text-lg py-3 group cursor-pointer px-12 bg-main-color md:tracking-wide">
                        <a href="tel:5302372893" className = "transition duration-200 delay-100 font-extrabold">Book Appointment</a>
                    </button>
                </div>
            </section>

			<section>
                    <div className=" p-5 pt-6 pb-8 lg:pt-28">
                        <div className="mx-auto space-y-6 lg:space-y-16 max-w-screen-xl">
                            <div className=" grid gap-12  lg:gap-24 place-items-center lg:grid-cols-3">
                                <Fade delay={5}>
                                    {Section1.map((s) => (
                                        <div key={s.id} className=" cursor-pointer flex text-center justify-center items-center space-y-5 flex-col">
                                            <img className="w-28" src={s.img} alt="#" />
                                            <div className=" flex flex-col space-y-5">
                                                <div className=" space-y-2">
                                                    <div>
                                                        <h2 className=" text-2xl font-Playfair text-main-color">{s.name}</h2>
                                                    </div>
                                                    <div>
                                                        <span className= "">
                                                            {s.desc}
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </Fade>
                            </div>
                        </div>
                    </div>
			</section>

            <section id="About" className=" bg-white">
                <div className="pb-6 pt-6 lg:pt-24 lg:pb-24">
                    <div className="lg:mx-5">
                        <div className="grid cursor-pointer grid-cols-1 lg:gap-0 gap-5 lg:grid-cols-2">
                            <JackInTheBox>
                                <div className="lg:h-[650px] h-[320px] lg:p-4 p-2 lg:rounded-lg md:block overflow-hidden">
                                    <img className="ease-in-out h-full lg:rounded-lg transition-all duration-1000 hover:scale-125" src={hpic}/>
                                </div>
                            </JackInTheBox>
                            <div className="space-y-2 px-4 lg:px-12 lg:space-y-8 lg:py-4 justify-center text-center">
                                <div className=" text-[30px] flex flex-col items-center lg:text-4xl tracking-wide space-y-2">
                                    <div className=" border-[1px] border-main-color w-16"></div>
                                    <h2 className=" text-main-color font-Playfair">
                                        About Us
                                    </h2>
                                </div>
                                <div className=" flex flex-col md:text-lg space-y-6 lg:space-y-8">
                                    <div className="space-y-4 flex flex-col">
                                        <span className="">
                                            Life's pressures can leave us feeling exhausted and overwhelmed. Balancing work and family responsibilities can sometimes push us to the brink of burnout. If you're feeling drained and stressed, it's essential to take a break and care for yourself. But where can you find that relief?
                                        </span>
                                        <span>
                                            At Relax Day Spa, we specialize in helping you release stress, reduce body fatigue, and ease the mental burdens that weigh you down. With years of experience in the relaxation and wellness industry, we provide treatments designed to restore your energy and bring balance back to your life. After a session with us, you'll return to your daily routine feeling refreshed, rejuvenated, and stress-free.
                                        </span>
                                        <span>
                                            We’re not doctors or psychologists—we’re relaxation experts dedicated to offering exceptional service to help you experience comfort and peace. Every service we offer is aimed at giving you those precious moments of relaxation that your body and mind crave.
                                        </span>
                                        <span>
                                            Don't hesitate to treat yourself! Your health is more valuable than anything money can buy. Take a step towards wellness and relaxation with us at Relax Day Spa. We promise you won't be disappointed.
                                        </span>
                                    </div>
									{/* <div className="">
                                        <span className=" text-center text-2xl text-main-color">
                                            Welcome to Relax Day Spa.
                                        </span>
                                    </div> */}
									<div className=" hidden lg:block">
										<button onClick={() => scrollSec()} className="group ">
											<RxDoubleArrowDown className="ease-in-out text-main-color delay-100 duration-150 group-hover:-translate-y-2 group-hover:text-main2 text-4xl" />
										</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

			<section id="Services" className="">
                <div className=" p-5 pt-6 pb-8 lg:pt-12 lg:pb-24">
                    <div className="mx-auto space-y-4 lg:space-y-12 max-w-screen-xl">
						<div className=" flex items-center space-y-5 lg:space-y-8 flex-col">
                            <div className=" text-[30px] lg:text-4xl tracking-wide space-y-2">
                                <div className=" border-[1px] border-main-color w-16"></div>
                                <h2 className=" text-main-color font-Playfair">
                                    Our Services & Combo
                                </h2>
                            </div>
                            <div className="text-center flex justify-center tracking-wide">
                                <div className=" lg:w-4/5">
                                    <span className=" lg:text-lg">
										At Relax Day Spa, we offer a variety of services to help you relax and rejuvenate. From soothing massages to revitalizing facials, 
										our treatments are designed to refresh your body and mind, leaving you feeling renewed and stress-free.
                                    </span>
                                </div>
                            </div>
                        </div>

						<div className="grid md:grid-cols-3 lg:mx-4 justify-items-center gap-2 lg:gap-8 cursor-pointer">
                            <Fade delay={10}>
                                {Section2.map((s) => (
                                    <div key={s.id} className="shadow-md rounded-lg space-y-2 mb-2 py-4 ease-in-out transition-all duration-1000 lg:hover:scale-105">
                                        <div className=" p-4">
                                            <img className="h-full w-full" src={s.img} />
                                        </div>
                                        <div className=" p-4 space-y-2">
                                            <div>
                                                <h1 className="text-main-color text-xl lg:text-2xl text-center">{s.name}</h1>
                                            </div>
                                            <hr></hr>

                                            {
                                                s.id === 5 ? (
                                                    <div className=" mt-4 flex flex-col space-y-2 text-center">
                                                        <span className="lg:text-lg">{s.desc1}</span>
                                                        <span className="lg:text-2xl text-lg">{s.desc2}</span>
                                                    </div>
                                                ): (
                                                    <div className=" mt-4 text-center">
                                                        {s.desc2 !== ''? (
                                                            <span className="lg:text-2xl text-lg">{s.desc1} | {s.desc2}</span>

                                                        ): (
                                                            <span className="lg:text-2xl text-lg">{s.desc1}</span>
                                                        )}
                                                    </div>
                                                )
                                            }
                                        </div>
                                        {/* <div className=" flex justify-center">
                                            <button className="hover:shadow-lg hover:-translate-y-2 hover:bg-black hover:text-white ease-in-out delay-100 duration-150 mt-5 md:text-xl text-black border-2 border-black text-lg py-3  group cursor-pointer px-12 md:tracking-wide">Book Now</button>
                                        </div> */}
                                    </div>
                                ))}
                            </Fade>
						</div>
					</div>
				</div>
			</section>

			<section>
                <div className=" p-5 pt-6 pb-8  lg:pt-12 lg:pb-24">
                    <div className="mx-auto space-y-6 lg:space-y-16 max-w-screen-xl">
                        <div className=" grid gap-8 lg:grid-cols-2">
                            <Fade delay={50}>
                                <div className="flex flex-col space-y-5 lg:space-y-8">
                                    <span  className=" text-xl lg:text-3xl text-black/40">Discover Serenity</span>
                                    <div className=" flex flex-col space-y-1 lg:space-y-2">
                                        <span className=" text-3xl lg:text-4xl font-Playfair text-black">
											Expert relaxation and rejuvenating
                                        </span>
                                        <span style={{fontFamily: 'Calligraffitti'}}  className="text-6xl text-main-color">
                                            Treatments
                                        </span>
                                    </div>
                                    <span className=" lg:text-lg text-black">
										Experience expert relaxation and rejuvenating treatments at Relax Day Spa, your premier destination for wellness. Offering a peaceful escape, we ensure each visit leaves you feeling refreshed and restored.                                    
									</span>
                                    <div className=" ">
                                        <ul className=" space-y-8">
											{Section3.map((s) => (
												<li key = {s.id} className=" flex flex-col">
													<div className=" flex space-x-2 text-2xl">
														<span  className="">0{s.id}</span>
														<span className=" font-bold italic">
															{s.name}
														</span>
													</div>
													<span className=" text-[#4A4A4A] lg:ml-8 ml-1 text-md">
														{s.desc}
													</span>
												</li>
											))}
                                            
                                        </ul>
                                    </div>
                                </div>
                            </Fade>
                            <div className=" lg:hidden rounded-xl flex">
                                <img className=" rounded-xl" alt="da" src={test1} />
                            </div>
                            <div className="hidden cursor-pointer rounded-xl lg:block lg:bg-bg2 lg:w-full lg:h-full lg:bg-center lg:bg-cover lg:bg-no-repeat">
                            </div>
                        </div>
                    </div>
                </div>
            </section>

			<section id="Contact" className=" bg-white">
                <div className="pt-6 lg:pt-12 h-full pb-6 space-y-4">
                    <div className="lg:p-4 h-full p-2">
                        <div className=" grid h-full lg:grid-cols-2 gap-8 lg:gap-12">
                            <div className="">
                                <iframe className=" w-full h-[500px] lg:h-full" id="" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3093.9668139543846!2d-121.62218562351488!3d39.15273643159682!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x809b54a84cea8931%3A0xaaa91e771bc5898a!2s1747%20Live%20Oak%20Blvd%2C%20Yuba%20City%2C%20CA%2095991%2C%20USA!5e0!3m2!1sen!2s!4v1728647513947!5m2!1sen!2s" frameborder="0" scrolling="no" marginheight="0" marginwidth="0"></iframe>
                            </div>
                            <Slide delay={4} direction="down">
                                <div className=" lg:py-24 mx-2 lg:mx-0 flex flex-col space-y-4">
                                    <div className=" text-[30px] lg:text-[44px] flex flex-col tracking-wide space-y-[1px] font-bold">
                                        <div className=" border-[1px] border-main-color w-16"></div>
                                        <h2 className=" font-Playfair text-main-color">
                                            Contact Us
                                        </h2>
                                    </div>
                                    <div className=" max-w-2xl flex flex-col space-y-5 lg:space-y-6">
                                        <div>
                                            <span>
                                                Conveniently located in the heart of Yuba City. Relax Day Spa offers a peaceful escape with a quiet space and private rooms for a truly serene experience. Our skilled Vietnamese staff provides personalized care to help you unwind and rejuvenate. We proudly serve the areas of Yuba City, Marysville, Sutter, Olivehurst, Live Oak, Gridley, Oroville, Colusa, and the surrounding communities.
                                            </span>
                                        </div>
                                        <div>
                                            <ul className="text-xl space-y-1">
                                                <li className=" text-main-color lg:text-2xl">Relax Day Spa</li>
                                                <li>1747 K Live Oak Blvd</li>
                                                <li>Yuba City CA 95991</li>
                                            </ul>
                                        </div>
                                        <div className="flex font-bold space-y-2 flex-col lg:text-xl">
                                            <span>
                                                Open hours: 9 AM to 7 PM
                                            </span>
                                            <span>
                                                Open Days: Monday to Saturday
                                            </span>
                                            <span>
                                                Available Appointments: Sunday
                                            </span>
                                        </div>
                                        <div>
                                            <span className=" lg:text-lg">
                                                We Accept Cash only or Zelle or Apple Pay
                                            </span>
                                        </div>

                                        <div className="">
                                        <button onClick={() => {
                                            window.location.href = "tel:5302372893";
                                        }} className=" mt-4 hover:shadow-lg  flex items-center justify-center space-x-2 bg-main-color text-white border-2 border-main-color hover:bg-main-color ease-in-out delay-100 duration-150 md:text-lg w-[200px] text-md py-3 px-4 group cursor-pointer md:tracking-wide">
                                                <span>Contact Us</span>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </Slide>
                        </div>
                    </div>
                </div>
            </section>
        </main>
    )
}