import {React , useState , useEffect} from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {faFacebookF, faInstagram, faYelp} from "@fortawesome/free-brands-svg-icons";


function FloatBtn(){
    const [isThere , SetisThere] = useState(false);
    
    const changeisThere = () =>{
        if ( (window.pageYOffset > 300) || (window.pageYOffset > window.screen.height - 5) ){
            SetisThere(true)
        }
        else{
            SetisThere(false)
        }
    }

    useEffect(() => {
        window.addEventListener('scroll' , changeisThere)
        return () => {
            window.removeEventListener('scroll' , changeisThere)
        }
    } , [])

    return (
        <main>
            <div onClick={() => window.location.href = '/Reviews'} className= {`${isThere?"opacity-100": "opacity-0"} flex flex-col lg:space-y-2 fixed bottom-4 lg:bottom-12 z-10 right-3 lg:right-8`}>
                <div className= "flex text-white shadow-md rounded-xl bg-main-color cursor-pointer justify-center items-center py-2 px-4">
                    Review Us
                </div>
            </div>
        </main>
    );
};

export default FloatBtn;