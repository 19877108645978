import {React} from "react"
import Logo from "../img/Logo.png";

export default function Footer(){

    const Section2 = [
		{
			id: 1,
			name: 'Relax Massage',
		},
		{
			id: 2,
			name: 'Body Scrub',
		},
		{
			id: 3,
			name: 'Hair Oil Treatment',
		},
		{
			id: 4,
			name: 'Hair Shampoo',
		},
		{
			id: 5,
			name: 'Special Combo',
		}
	];


    return(
        <footer className="bg-white cursor-pointer">
            <div className="mx-auto max-w-screen-xl px-6 pb-6 pt-16 lg:px-8 lg:pt-24">
                <div className="grid lg:space-x-12 grid-cols-1 gap-12 lg:grid-cols-3">
                    <div className="grid justify-items-center">
                        <div className="">
                            {/* <div className=" ">
                                <h2 className="flex md:hidden text-2xl  italic tracking-wider font-bold text-main-color font-Playfair">M Spa Beauty</h2>
                                <h2 className="flex md:hidden text-xl  italic font-thin tracking-wider text-main-color font-Playfair">Lash & Nail</h2>
                            </div> */}
                            <img alt ="#" className="w-44 md:flex" src={Logo} />
                        </div> 
                        {/* <ul className="mt-8 flex justify-center gap-6 md:gap-8">
                            <li className="">
                                <a href="https://www.yelp.com/biz/m-spa-and-beauty-nails-plano-3" target="_blank" className="text-teal-700 transition hover:text-teal-700/75">
                                    <img src="https://img.icons8.com/fluent/30/000000/yelp.png"/>
                                </a>
                            </li>
                            <li>
                                <a href="mailto:mspabeautynails@gmail.com" target="_blank" className="text-teal-700 transition hover:text-teal-700/75">
                                    <img src="https://img.icons8.com/fluent/30/000000/facebook.png"/>
                                </a>
                            </li>
                            <li>
                                <a href="https://www.instagram.com/mspabeautynails/" target="_blank" className="text-teal-700 transition hover:text-teal-700/75">
                                    <img src="https://img.icons8.com/fluent/30/000000/instagram-new.png"/>
                                </a>
                            </li>
                            <li>
                                <a href="https://www.google.com/maps/place/M+Spa+%26+Beauty+Nails/@33.0022604,-96.7675107,17z/data=!3m1!4b1!4m6!3m5!1s0x864c22114ed50c57:0xf813007a332e70df!8m2!3d33.0022559!4d-96.7649304!16s%2Fg%2F11bx1s17w_?entry=ttu" target="_blank" className="text-teal-700 transition hover:text-teal-700/75">
                                    <img src="https://img.icons8.com/fluency/30/google-logo.png"/>
                                </a>
                            </li>
                        </ul> */}
                    </div>
                    <div className="grid grid-cols-1 gap-8 sm:grid-cols-2 md:grid-cols-4 lg:col-span-2">
                        <div className="text-center sm:text-left">
                            <p className="text-lg font-medium text-main-color font-Playfair">About Us</p>
                            <ul className="mt-8 space-y-4 text-sm">
                                <li>
                                    <a href="/" className=" transition text-black t-underline t-underline-black border-b-yellow-500">
                                        Home
                                    </a>
                                </li>
                                <li>
                                    <a  href="/Services" className=" transition text-black t-underline t-underline-black border-b-yellow-500">
                                        Services
                                    </a>
                                </li>
                                <li>
                                    <a  href="/Gallery" className=" transition text-black t-underline t-underline-black border-b-yellow-500">
                                        Galery
                                    </a>
                                </li>
                                {/* <li>
                                    <a href="https://www.fresha.com/a/m-spa-beauty-nails-plano-308-south-coit-road-yva9f2lc/booking?pId=1162111" target="_blank" className="transition text-black t-underline t-underline-black border-b-yellow-500">
                                        Booking
                                    </a>
                                </li> */}
                            </ul>
                        </div>
                        <div className="text-center sm:text-left">
                            <p className="text-lg font-medium text-main-color font-Playfair">Services</p> 
                            <ul className="mt-8 space-y-4 text-sm">
                                {Section2.map((d) => (
                                    <li key={d.id}>
                                        <a target="_blank" href="/Services" className=" transition text-black t-underline t-underline-black border-b-yellow-500">
                                            {d.name}
                                        </a>
                                </li>
                                ))}
                            </ul>
                        </div>
                        <div className="text-center sm:text-left">
                            <p className="text-lg font-medium text-main-color font-Playfair">Business Hours</p>
                            <ul className="mt-8 space-y-4 text-sm">
                                <li>
                                    <a className=" transition text-black t-underline t-underline-black border-b-yellow-500">
                                        Mon-Sun
                                    </a>
                                </li>
                            </ul>
                        </div>
                        <div className="text-center sm:text-left">
                            <p className="text-lg font-medium text-main-color font-Playfair">Contact Us</p>
                            <ul className="mt-8 space-y-5 text-sm">
                                <li>
                                    <a className="flex items-center justify-center gap-1.5 ltr:sm:justify-start rtl:sm:justify-end" href="tel:5302372893">
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            className="h-5 w-5 shrink-0 text-main-color font-Playfair"
                                            fill="none"
                                            viewBox="0 0 24 24"
                                            stroke="currentColor"
                                            strokeWidth="2"
                                        >
                                            <path
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            d="M3 5a2 2 0 012-2h3.28a1 1 0 01.948.684l1.498 4.493a1 1 0 01-.502 1.21l-2.257 1.13a11.042 11.042 0 005.516 5.516l1.13-2.257a1 1 0 011.21-.502l4.493 1.498a1 1 0 01.684.949V19a2 2 0 01-2 2h-1C9.716 21 3 14.284 3 6V5z"
                                            />
                                        </svg>

                                        <span className="flex-1 text-gray-700">(530) 237-2893</span>
                                    </a>
                                </li>

                                <li className="flex items-start justify-center gap-1.5 ltr:sm:justify-start rtl:sm:justify-end">
                                    <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    className="h-5 w-5 shrink-0 text-main-color font-Playfair"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    stroke="currentColor"
                                    strokeWidth="2"
                                    >
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z"
                                    />
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        d="M15 11a3 3 0 11-6 0 3 3 0 016 0z"
                                    />
                                    </svg>
                                    <a  target="_blank"  rel="noreferrer" href = "https://maps.app.goo.gl/EzXEJxL8wi2w8gGz5" className="-mt-0.5 flex-1 not-italic text-gray-700">
                                        1747 K Live Oak Blvd Yuba City CA 95991
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>

                <div className="mt-12 border-t border-gray-100 pt-6">
                    <div className="text-center sm:flex sm:justify-between sm:text-left">
                        <p className="text-sm text-gray-500">
                            <span className="block sm:inline">All rights reserved.</span>
                        </p>  
        
                        <p className="mt-4 text-sm text-gray-500 sm:order-first sm:mt-0">
                            &copy; 2024 Relax Day Spa
                        </p>
                    </div>
                </div>
            </div>
        </footer>
    );
};